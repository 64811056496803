
<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">Legal notice</h1>
      <tiles>
        <card-component
          title="Site identification"
          icon="id-card-o"
          class="tile is-child"
        >
          <div class="content">
            <p> Site name: ASTERICS<br/>
              Full site URL: <a href="https://asterics.miat.inrae.fr">https://asterics.miat.inrae.fr</a><br/>
              Site editor: FRENCH NATIONAL RESEARCH INSTITUTE FOR AGRICULTURE, FOOD AND ENVIRONMENT - INRAE<br/>
              INRAE is a public scientific and technical<br/>
              establishment headquartered at 147, rue de l’Université – 75338 Paris CEDEX 07<br/>
              Its statutes are published in the French rural and maritime fishing code <br/>
              (Code rural et de la pêche maritime) (Articles R831-1 et seq.)<br/>
              It is represented by its President, Mr Philippe MAUGUIN.<br/>
              Email contact: web@inrae.fr - Phone number: (+33) (0)1 42 75 00 00<br/>
              To contact ASTERICS Team: <a href="mailto:asterics-tlse@inrae.fr">asterics-tlse@inrae.fr</a><br/>
              To <a href="https://github.com/astericsInrae/asterics-issues/issues/new?labels=bug&projects=&template=bug_report.md&title=%5BBUG%5D">report a bug</a> or <a href="https://github.com/astericsInrae/asterics-issues/issues/new?labels=Suggests&projects=&template=feature_request.md&title=%5BSUGGESTS%5D">make a suggestion</a>:
              </p>
          </div>
        </card-component>
        <card-component
          title="Identification numbers"
          icon="sort-numeric-down"
          class="tile is-child"
        >INRAE<br/>
          SIREN : 180070039<br/>
          APE code : 7219Z<br/>
          Intra-Community VAT number : FR 57 1800700039<br/><br/>
          INRAE Toulouse<br/>
          N° SIRET INRA Toulouse: 18007003901134<br/>
          APE code: 7219Z
        </card-component>
      </tiles>
      <tiles>

      </tiles>
      <tiles>
        <card-component
          title="Site publication"
          icon="user"
          class="tile is-child"
        >Publication Director : Mr Philippe MAUGUIN (PDG INRA)<br/>
          Publication Manager : Mrs Nathalie VIALANEIX (ASTERICS coordinator)
        </card-component>
         <card-component
          title="Site hoster"
          icon="cloud"
          class="tile is-child"
        >INRAE<br/>
          Public scientific and technical establishment<br/>
          147, rue de l’Université – 75338 Paris CEDEX 07<br/>
          +33 (0)1 42 75 90 00<br/>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import Tiles from '@/components/ui/Tiles'
import CardComponent from '@/components/ui/CardComponent'

export default {
  name: 'About',
  components: {
    CardComponent,
    Tiles
  }
}
</script>
